<template>
  <div class="ac-statistics-card">
    <div class="value-slot">
      <slot name="statisticsValue"></slot>
    </div>
    <div class="title-slot">
      <slot name="statisticsTitle"></slot>
    </div>
    <!-- <div class="count-slot" :style="{background: background}">
      <a-descriptions :column="2" :colon="false">
        <slot name="countDescription"></slot>
      </a-descriptions>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'ACStatisticsCard',
  props: {
    background: {
      type: String,
      default: '#F7FCF8'
    }
  }
}
</script>

<style lang="less">
.ac-statistics-card {
  padding: 16px 16px 16px 0;
  .value-slot {
    font-size: 30px;
    font-weight: 500;
    line-height: 42px;
    text-align: center;
  }
  .title-slot {
    font-size: 18px;
    color: rgba(0,0,0,0.85);
    line-height: 25px;
    text-align: center;
  }
  .count-slot {
    height: 100px;
    border-radius: 8px;
    margin-top: 24px;
    padding-top: 12px;
    display: flex;
    align-items: center;

    .ant-descriptions-item-label {
      font-size: 16px;
      color: rgba(0,0,0,0.85);
      line-height: 22px;
      display: inline-block;
      min-width: 128px;
      padding-left: 48px;
    }
    .ant-descriptions-item-content {
      font-size: 16px;
      font-weight: 500;
      color: #096DD9;
      line-height: 22px;
    }
  }
}
</style>
